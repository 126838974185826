$font-weight-base: 200;

$white: #FFF;
$black: #000;
$secondary: #FFF;

$navbar-brand-padding-y: 16px;
$navbar-nav-link-padding-x: 1rem;
$navbar-light-toggler-border-color: transparent !default;

$light:         #5F74DD !default;
$navbar-light-color:                rgba($white, 1) !default;
$navbar-light-hover-color:          rgba($white, .7) !default;
$navbar-light-active-color:         rgba($white, .7) !default;

